import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams }   from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createAppointment, newAppointment } from "../redux/actions/offices";
import session     from "../utils/session";
import obtainScheduleData from "../middlewares/obtainScheduleData";
import sendSwal from '../middlewares/sendSwal'
import { useAuth } from "../components/AuthProvider";
import {SelectPatient}         from '../components/SelectPatient';
import 'moment/locale/es';
import Moment from 'react-moment';
import moment from 'moment';

export const DayAndHourSelector = () => {
  const { dataAppointment, handleSaveAppoinmentData }  = useAuth();
  const navigate   = useNavigate();
  const currentUrl = useParams();
  const dispatch = useDispatch();
  const [ dayHasHour, setDayHasHour ] = useState([]);
  const [showHours, setShowHours] = useState(true);

  const { professional: professionalData, secretary: secretaryData } = session.getUser();

  const [lastDaysSelected, setLastDaysSelected] = useState(null);
  const [lastDaysSelectedFinish, setLastDaysSelectedFinish] = useState(null);

  const Storage = JSON.parse(localStorage.CurrentUrl);
  
  const schedules = useSelector((state) => state.offices.scheduleOptions);
  
  useEffect(() => {
    if (dataAppointment) {  
      if (lastDaysSelected != null) {
        if (dataAppointment.optionDate) {
          selectDays(dataAppointment.optionDate);
        } else {
          selectDays(0);
        }        
      }
    }
  }, []);

  useEffect(() => {
    if (dataAppointment?.optionDate) {
      if (lastDaysSelected != null || lastDaysSelectedFinish != null) {
        console.log('DISPATCH');
        dispatch(createAppointment(dataAppointment));
      }
    } else {
      console.log('DISPATCH');
      dispatch(createAppointment(dataAppointment));
    }
    
  }, [lastDaysSelected, lastDaysSelectedFinish]);
  
  useEffect(() => {
  
    if (schedules) {
      console.log('schedules', schedules);
      const days = obtainScheduleData(schedules).days;
      let auxDayHasHour = [];
      for (let i=0 ; i < days.length ; i++) {
        const auxHours = schedules.find(element => element.day == days[i]);
        auxDayHasHour.push({days: days[i], hasHour: (auxHours.items.length > 0), hours: auxHours.items, non_workday: auxHours.non_workday })
      }
      setDayHasHour(auxDayHasHour);
    }
  }, [schedules]);
  
  const handleClose = () => {
    navigate(`/appointments/new`);
  }

  const openHours = (day) => {
    if (day.hasHour) {
      setShowHours(true);
    } else {
      setShowHours(false)
    }
  }
  const onClickHour = (day, hour, dataAppointment) => {
    
    let auxData = { ...dataAppointment };

    if (hour.includes("*")) {

      let mainText = `Estás seguro que quieres asignar un sobreturno el día ${moment(day.days, 'YYYY-MM-DD').format('DD/MM/YYYY')} a las ${hour.replaceAll("*","")}hs.`
      let textConfirmButtom = "Confirmar"
      let textCancelButtom ="Cancelar"

      auxData.date = day.days;
      auxData.hour = hour.replaceAll('*', '');
      auxData.overschedule = 1;
      
      sendSwal(mainText, textConfirmButtom, textCancelButtom, () => goToConfirmAppointment(auxData), true);

    } else {
      auxData.date = day.days;
      auxData.hour = hour;
    
      goToConfirmAppointment(auxData);
    }
  }

  const goToConfirmAppointment = (auxData) => {
    
    
    if (Storage?.id?.id) {
      auxData = { ...auxData, patient: parseInt(Storage.id.id) }
      handleSaveAppoinmentData(auxData);
      navigate(`/appointments/${Storage.id.id}/confirm-new-appointment`);
    } else {
      handleSaveAppoinmentData(auxData)
      handleShowSelectPatient();
    }
    
  }

  const showHour = (day, hour) => {
    const realHour = hour.replaceAll('*', '').replaceAll('!', '');
    if (hour.includes("*!")) {
      return(<div key={`hour-${day}-${hour}`} className="text-center bg-white rounded-2xl my-1 mx-1 py-1 px-2 text-[#F3565D]">{realHour}</div>)
    } else if (hour.includes("*")) {
      return(<div key={`hour-${day}-${hour}`} onClick={ () => onClickHour(day, hour, dataAppointment) } className="text-center bg-gray-200 rounded-2xl my-1 mx-1 py-1 px-2 text-[#F3565D]">{realHour}</div>)
    } else if (hour.includes("!")) {
      return(<div key={`hour-${day}-${hour}`} className="bg-white text-center rounded-2xl my-1 mx-1 py-1 px-2 text-gray-300">{realHour}</div>)
    } else {
      return(<div key={`hour-${day}-${hour}`} onClick={ () => onClickHour(day, hour, dataAppointment) } className="text-center bg-[#3388FF] rounded-2xl text-white my-1 mx-1 py-1 px-2 pointer">{realHour}</div>)
    }
  }
  const handleChange = (e) => {

    console.log('handleChange', e.target.value);    
    selectDays(e.target.value);
    setLastDaysSelected(e.target.value);
    
  };

  const selectDays = (count) => {

    console.log('selectDays', count);

    let auxData = { ...dataAppointment }
    auxData.optionDate = count;
    switch (count) {
      case '0': 
        auxData.firstDate = moment().format('Y-MM-DD');
        break;
      case '7': 
        auxData.firstDate = moment().add(1, 'w').format('Y-MM-DD');
        break;
      case '14': 
        auxData.firstDate = moment().add(2, 'w').format('Y-MM-DD');
        break;
      case '20': 
        auxData.firstDate = moment().add(20, 'd').format('Y-MM-DD');
        break;
      case '30': 
        auxData.firstDate = moment().add(1, 'M').format('Y-MM-DD');
        break;
      case '60': 
        auxData.firstDate = moment().add(2, 'M').format('Y-MM-DD');
        break;
      case '90': 
        auxData.firstDate = moment().add(3, 'M').format('Y-MM-DD');
        break;
      case '180': 
        auxData.firstDate = moment().add(6, 'M').format('Y-MM-DD');
        break;
    }
    auxData.lastDate = moment(auxData.firstDate).add(7, 'd').format('Y-MM-DD'); // getDate(parseInt(appointmentData.date));
    handleSaveAppoinmentData(auxData);
  }

  const handleShowSelectPatient = () => {
    navigate(`/appointments/select-patient`);
  }

  const handleAddWeek = () => {
    
    let auxData = { ...dataAppointment }
    auxData.lastDate = moment(auxData.lastDate).add(7, 'd').format('Y-MM-DD'); // getDate(parseInt(appointmentData.date));
    handleSaveAppoinmentData(auxData);

    setLastDaysSelectedFinish(auxData.lastDate);

  }

  return (
    <>
    <div className="pb-5 h-screen px-2">
      {/* <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px', backgroundColor:'white'}} className="mt-[40px] sm:mt-0 overflow-x-auto h-full">
        <div className="mt-4 flex justify-between px-4 sm:px-0"> */}
      <div className="max-h-full max-w-md bg-white overflow-scroll md:max-w-lg font-sans px-2">
        
        <div className="sticky top-0 bg-white pt-6 pb-2">
          
          <div className="mt-4 flex items-start justify-between pb-4">
            {/* <h3 className="text-lg text-center font-medium text-gray-900"></h3> */}
            <h3 className="text-xl text-[#1C1243]"><span className="font-bold capitalize">Nuevo Turno</span></h3> 
            <button className="px-1 pt-2" onClick={() => handleClose()}>                
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
              </svg>
            </button> 
          </div> 

          <div className="pt-4">
            <label className="text-base font-semibold">Día y Hora</label>
          </div>          
          <select
            className="select-style"
            onChange={handleChange}
            name="date"
            value={ dataAppointment.optionDate ? dataAppointment.optionDate : 0 }
          >
            <option value={0}>Esta semana</option>
            <option value={7}>En una semana</option>
            <option value={14}>En dos semanas</option>
            <option value={20}>En 20 días</option>
            <option value={30}>En 1 mes</option>
            <option value={60}>En 2 meses</option>
            <option value={90}>En 3 meses</option>
            <option value={180}>En 6 meses</option>
          </select>

        </div>
        
        {/* <div className={`text-red-700 grid grid-cols-7`}>
          {dayHasHour.map((day, indexDay) => (
            <div onClick={() => openHours(day)}>
              <div className={`${ day.hasHour ? 'text-red-800 font-semibold' : 'text-black' }`}><Moment format="ddd" locale="es">{day.days}</Moment></div>
              <div className={`${ day.hasHour ? 'text-red-800 font-semibold' : 'text-black' }`}><Moment format="DD">{day.days}</Moment></div>
              {showHours && 
                <div className={`${ day.hasHour ? 'text-red-800 font-semibold' : 'text-black' }`}>{day.hours.map((hour) => (<div>{hour}</div>))}</div>
              }
            </div>
          ))}
        </div> */}
        {dayHasHour.map((day, indexDay) => (
          day.non_workday != 1 
          ?
            <div key={`events-${indexDay}`}>
              <div className="" onClick={() => openHours(day)}>          
                <div className={`text-center capitalize mt-6 text-base ${ day.hasHour ? 'mb-2 text-[#1C1243] font-semibold' : 'hidden'}`}><Moment format="dddd" locale="es">{day.days}</Moment> <Moment format="DD">{day.days}</Moment>/<Moment format="MM">{day.days}</Moment></div>
                <div className={`${day.non_workday == 0 && 'flex flex-wrap justify-start text-[#1C1243]'}`}>
                  {day.hours.map((hour) => showHour(day, hour))}
                </div>
              </div>
              <div>
                {indexDay === dayHasHour.length -1 && 
                  <button onClick={handleAddWeek} className="secondary-button">Ver semana siguiente</button>
                }
              </div>
            </div>
        :
          day.hasHour &&
            <div key={`events-${indexDay}`}>
              <div>
                <div className="" >          
                <div className={`text-center capitalize mt-8 text-base ${ day.hasHour ? 'mb-2 text-[#FF6A5D] font-semibold' : 'hidden'}`}><Moment format="dddd" locale="es">{day.days}</Moment> <Moment format="DD">{day.days}</Moment>/<Moment format="MM">{day.days}</Moment></div>
                <div style={{margin: '0 auto', width:'95%'}} className={`${day.non_workday == 0 && 'text-[#1C1243]'}`}>
                  <div className="text-center text-sm font-sans">Día no laborable</div>
                </div>
                </div>
              </div>
              <div className="mx-4">
                {indexDay === dayHasHour.length -1 && 
                  <button onClick={handleAddWeek} className="secondary-button">Ver semana siguiente</button>
                }
              </div>
            </div>
        ))}
      </div>
    </div>


        {/* <>
        <div className="fixed inset-0 z-[20000] overflow-y-auto">
            <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => handleShowSelectPatient()}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-[80vw] h-[185px] py-1 px-2 mx-auto bg-white rounded-xl shadow-lg">
                    <div className="sm:flex">
                        <div className="text-center sm:ml-4 sm:text-left">
                            <div className="items-center gap-2 my-3 sm:flex text-[#1C1243]">
                                <SelectPatient section={"Turno"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </> */}

    </>
  )
}